* {
    box-sizing: border-box;
    margin: 0;
    padding: -7px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #1a1a1c;
    color: blanchedalmond;
}

 .coin-app{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
    color: #fff;
} 

 .coin-search{
    margin-bottom: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} 

 .coin-text{
    margin-bottom: 32px;
    margin-top: 3rem;
    text-align: center;
    font-size: 34px;
    text-transform: uppercase;
    color:blanchedalmond;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
} 


.coin-input{
    padding-left: 16px;
    width: 350px;
    height: 80px;
    border-radius: 34px;
    outline: none;
    border: none;
    background-image: linear-gradient(
        -225deg,
        #ac32e4 35%,
        #7918fc 18%,
        #f8f2e5 82%
    );
    color: blanchedalmond;
} 

::placeholder{
    color: rgb(105, 219, 70);
    font-size: 20px;
    font-weight: bolder;
    font-family: cursive;
}
