/* .coin-container{
    display: flex;
    justify-content: center;

}

.coin-row{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #d7d7d7;
    border-top: 1px solid #d7d7d7;
    border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    border-radius: 20px;
    width: 980px;
} 

 .coin{
    display: flex;
    align-items: center;
    min-width: 300px;
    padding-right: 24px; 
 } 

 .coin h1{
   font-size: 16px;
   width: 150px;
} 

.coin img{
    height: 30px;
    width: 30px;
    margin-right: 10px;
} 

 .coin-symbol{
    text-transform: uppercase;

}

.coin-data{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
} 

.coin-price{
    width: 140px;
} 

 .coin-volume{
    width: 260px;
}
.coin-marketcap{
    width: 220px;
}

 .coin-percent {
    width: 38%;
}

 .red{
    color: #f00606
}

.green{
    color: rgb(76, 245, 9);
} 
  */


  
.coin-container{
    display: flex;
    justify-content: center;
}

.coin-row{
    display: flex;
    justify-content: start;
    flex-direction: row;
    width: 990px;
    height: 90px;
    align-items: center;
    border-radius: 30px;
    /*  background-image: linear-gradient(
        -225deg,
        #ac32e4 35%,
        #7918fc 18%,
        #f8f2e5 82%
    ); */
    border-top: 2px solid blue;
    border-bottom: 2px solid red;
}

.coin{
    display: flex;
    min-width: 300px;
    align-items: center;
    padding-left: 24px;

}

img{
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

h1{
    width: 150px;
    font-size: 16px;
}

.coin-symbol{
    text-transform: uppercase;
}

.coin-data{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    text-align: center;
}

.coin-price{
    width: 90px;
    padding-left: 2.5rem;

}

.coin-marketcap{
    width: 220px;
}


.coin-volume{
    width: 260px;
    padding-left: 1.5rem;
}


.coin-percent{
    width: 88px;
}

.red{
    color: red;
}

.green{
    color: green;
}